import React from "react"
import Layout from "../../components/layouts/Layout"
import { graphql, Link } from "gatsby"
import SEO from "../../components/Seo"
import BlockContent from "@sanity/block-content-to-react"

const ArticlePage = ({ data }) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: data.sanityArticle.title,
    description: data.sanityArticle.description,
    image: "",
    author: {
      "@type": "Organization",
      name: "Archibald James Wine & Ciderworks",
    },
    publisher: {
      "@type": "Organization",
      name: "Archibald James Wine & Ciderworks",
      logo: {
        "@type": "ImageObject",
        url: "https://archibaldjames.com/logo.png",
      },
    },
    // datePublished: ""
  }
  return (
    <>
      <Layout>
        <SEO
          schemaMarkup={schema}
          title={data.sanityArticle.title}
          description={data.sanityArticle.description}
        />
        <article className="prose prose-lg mx-auto">
          <h1>{data.sanityArticle.title}</h1>
          <BlockContent blocks={data.sanityArticle._rawBody}></BlockContent>
        </article>
      </Layout>
    </>
  )
}

export default ArticlePage

export const query = graphql`
  query ($id: String!) {
    sanityArticle(id: { eq: $id }) {
      title
      description
      slug {
        current
      }
      _rawBody
    }
  }
`
